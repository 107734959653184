import React, { useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import Form from './form';
import {
    SttExpansionPanel,
    SttTextItem,
    SttHeading,
    SttGrid,
    SttTranslateHook
} from '@stt-componentes/core';

const useStyles = makeStyles(theme => ({
    titulo: {
        display: 'flex',
        justifyContent: 'space-around',
        flexDirection: 'column'
    }
}));

const Laudo = (props) => {
    const { dados, proximoExame } = props;
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();

    return (
        <SttGrid container spacing={3}>
            <SttGrid item xs={12}>
                <SttHeading variant="h3" color="primary" align="center" className={classes.titulo}>
                    <span>{`${dados.exame.descricao_exame} - ${dados.exame.data_exame_formatada}`}</span>
                    <span>{`${dados.exame.nome_instituicao} - ${dados.exame.nome_cidade}/${dados.exame.sigla_uf}`}</span>
                </SttHeading>
                <SttExpansionPanel
                    title={strings.paciente}
                    compact
                    children={
                        <div>
                            <SttTextItem title={strings.nome} content={dados.exame.nome_paciente} />
                            <SttTextItem title={strings.dataNascimento} content={dados.exame.data_nascimento_paciente_formatada} />
                            <SttTextItem title={strings.sexo} content={dados.exame.sexo_paciente} />
                            { dados.indicacao.peso_paciente && <SttTextItem title={strings.peso} content={`${dados.indicacao.peso_paciente} kg`} /> }
                            { dados.indicacao.altura_paciente && <SttTextItem title={strings.altura} content={`${dados.indicacao.altura_paciente} cm`} />}
                            <SttTextItem key="6" title={strings.idade} content={`${dados.exame.idade_paciente} anos`} />
                            { dados.exame.cartao_sus_paciente && <SttTextItem title={strings.cns} content={dados.exame.cartao_sus_paciente} /> }
                        </div>
                    }
                />
                {
                    (dados.indicacao.fumante || dados.indicacao.etilista || dados.indicacao.usuario_drogas || dados.indicacao.exposicao_sol_horas) &&
                    <SttExpansionPanel
                        title={strings.habitos}
                        compact
                        children={
                            <div>
                                {dados.indicacao.fumante && <SttTextItem title={strings.fumante} content={dados.indicacao.fumante} />}
                                {dados.indicacao.cigarros_dia && <SttTextItem title={strings.cigarrosDia} content={dados.indicacao.cigarros_dia} />}
                                {dados.indicacao.tempo_tabagismo && <SttTextItem title={strings.tempoTabagismo} content={dados.indicacao.tempo_tabagismo} />}
                                {dados.indicacao.tempo_ex_tabagismo && <SttTextItem title={strings.tempoExTabagista} content={dados.indicacao.tempo_ex_tabagismo} />}
                                {dados.indicacao.tipo_fumo && <SttTextItem title={strings.tipoFumo} content={dados.indicacao.tipo_fumo} />}
                                
                                {dados.indicacao.etilista && <SttTextItem title={strings.etilista} content={dados.indicacao.etilista} />}
                                {dados.indicacao.tempo_etilismo && <SttTextItem title={strings.tempoEtilismo} content={dados.indicacao.tempo_etilismo} />}
                                {dados.indicacao.tempo_ex_etilista && <SttTextItem title={strings.tempoExEtilista} content={dados.indicacao.tempo_ex_etilista} />}
                                {dados.indicacao.tipo_bebida && <SttTextItem title={strings.tipoBebida} content={dados.indicacao.tipo_bebida} />}
                                
                                {dados.indicacao.usuario_drogas && <SttTextItem title={strings.usuarioDrogas} content={dados.indicacao.usuario_drogas} />}
                                {dados.indicacao.tempo_usuario_drogas && <SttTextItem title={strings.tempoUsuarioDrogas} content={dados.indicacao.tempo_usuario_drogas} />}
                                {dados.indicacao.tempo_ex_usuario_drogas && <SttTextItem title={strings.tempoExUsuarioDrogas} content={dados.indicacao.tempo_ex_usuario_drogas} />}
                                {dados.indicacao.tipo_droga && <SttTextItem title={strings.tipoDroga} content={dados.indicacao.tipo_droga} />}

                                {dados.indicacao.exposicao_sol_horas && <SttTextItem title={strings.exposicaoSolHorasDia} content={dados.indicacao.exposicao_sol_horas} />}
                                {dados.indicacao.exposicao_sol_meses && <SttTextItem title={strings.exposicaoSolMesesAno} content={dados.indicacao.exposicao_sol_meses} />}
                                {dados.indicacao.protecao_solar_tempo && <SttTextItem title={strings.tempoUsoProtecaoSolar} content={dados.indicacao.protecao_solar_tempo} />}
                                {dados.indicacao.protecao_solar_frequencia && <SttTextItem title={strings.frequenciaUsoProtecaoSolar} content={dados.indicacao.protecao_solar_frequencia} />}
                            </div>
                        }
                    />
                }
                <SttExpansionPanel
                    title={strings.outrasInformacoes}
                    compact
                    children={
                        <div>
                            {dados.exame.observacao_tecnica && <SttTextItem title={strings.observacaoTecnica} content={dados.exame.observacao_tecnica} /> }
                            <SttTextItem title={strings.pacienteUsuarioProtese} content={dados.indicacao.usuario_protese} />
                            {dados.indicacao.protese && <SttTextItem title={strings.situacaoProtese} content={dados.indicacao.situacao_protese} />}
                            {dados.indicacao.protese && <SttTextItem title={strings.abrangenciaProtese} content={dados.indicacao.abrangencia_protese} />}
                            {dados.indicacao.protese && <SttTextItem title={strings.tratamentoPrevio} content={dados.indicacao.tratamento_previo} />}
                            {dados.indicacao.possibilidade_trauma_local && <SttTextItem key="34" title={strings.possibilidadeTraumaLocal} content={dados.indicacao.possibilidade_trauma_local} />}
                        </div>
                    }
                />
                <SttExpansionPanel
                    title={strings.indicacaoClinica}
                    compact
                    children={
                        <div>
                            <SttTextItem title={strings.queixaPrincipal} content={dados.indicacao.queixa_principal} />
                        </div>
                    }
                />
                <Form 
                    dados={dados}
                    callbackProximoExame={proximoExame}
                />
            </SttGrid>
        </SttGrid>
    )
}

Laudo.propTypes = {
    dados: PropTypes.object.isRequired,
    proximoExame: PropTypes.func
};

export default Laudo;
