import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import axios from 'axios';
import { getHeaders } from '../../request';
import ListaAnexos from './anexos';
import SttGaleriaImagens from '@stt-componentes/galeria-telediagnostico';
import { SttTranslateHook } from '@stt-componentes/core';
import { useSignal, useSignalEffect } from '@preact/signals-react';
import { useSignals } from '@preact/signals-react/runtime';

const useStyles = makeStyles((theme) => ({
    divListaImagens: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
}));

const Imagens = (props) => {
    const { exame } = props;
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();
    const anexos = useSignal([]);

    useSignals();

    useSignalEffect(() => {
        axios.get(`${global.gConfig.url_base_exames}/exame/${exame.id_exame}/anexo`, { headers: getHeaders() })
            .then((response) => {
                anexos.value = response.data;
            })
            .catch(err => {
                console.log('err', err);
            });
    });


    return (
        <>
            <SttGaleriaImagens
                idExame={exame.id_exame}
                strings={strings}
                config={global.gConfig}
                tokenTelessaude={getHeaders()}
                legendas={exame.dataImagens}
            />
            {
                anexos.value.length > 0 &&
                <div className={classes.divListaImagens}>
                    <ListaAnexos anexos={anexos.value} />
                </div>
            }
        </>
    )

}

Imagens.propTypes = { idExame: PropTypes.number.isRequired };

export default Imagens;