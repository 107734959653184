import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom'
import { makeStyles } from "@material-ui/core/styles";
import StickyBox from "react-sticky-box";
import PropTypes from 'prop-types';
import axios from 'axios';
import { getHeaders } from '../../request';
import { SttGrid, SttButton, SttHidden, SttLoading, SttTranslateHook, SttTabs } from '@stt-componentes/core';
import Laudo from './laudo';
import Imagens from './imagens';
import Invalidar from '../exame/invalidar';
import { useSignals } from '@preact/signals-react/runtime';
import { useSignal } from '@preact/signals-react';
import alerta from '../../signals/alerta';
import { laudar } from '../../signals/laudo';

const useStyles = makeStyles(theme => ({
    wrapperBotao: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    }
}));

const Container = (props) => {
    const { exame, idRede, proximoExame, loading } = props;

    const { strings } = useContext(SttTranslateHook.I18nContext);
    const location = useLocation();
    const classes = useStyles();
    const ESTOMATO_API_BASE_URL = global.gConfig.url_base_estomato;

    const contextoFabricalaudo = useSignal(location.pathname === '/laudo');
    const invalidar = useSignal(false);

    useSignals();

    const handleCloseAlerta = () => {
        alerta.value = { ...alerta.value, open: false };
        if (contextoFabricalaudo.value) {
            proximoExame();
        } else {
            laudar.value = false;
        }
    }

    const voltar = () => {
        // Controle de componentes. Volta para tela anterior
        laudar.value = false;

        // Cancela emissão do laudo
        cancelarEmissao();
    }

    const cancelarEmissao = () => {
        axios
            .post(`${ESTOMATO_API_BASE_URL}/laudo/cancelar`, { idExame: exame.exame.id_exame }, { headers: getHeaders() })
            .catch(err => console.log(err));
    }

    const ignorarExame = () => {
        loading(true, strings.ignorandoExame);

        // Insere exame ignorado
        axios
            .post(`${ESTOMATO_API_BASE_URL}/ignorar-exame`, { idExame: exame.exame.id_exame }, { headers: getHeaders(), timeout: 15000 })
            .then((resposta) => {
                // Carrega o próximo exame
                proximoExame();
            })
            .catch(err => {
                console.log(err);
                alerta.value = {
                    title: strings.erro,
                    message: strings.erroIgnorarExame,
                    type: 'error',
                    open: true,
                    options: [
                        {
                            title: strings.ok,
                            onClick: handleCloseAlerta
                        }
                    ],
                    onClose: handleCloseAlerta
                };
            });
    }

    return (
        <>
            <SttGrid container spacing={3}>
                <SttGrid item xs={12} className={classes.wrapperBotao}>
                    {
                        <SttButton
                            variant="contained"
                            danger={+true}
                            onClick={() => invalidar.value = true}
                        >
                            {strings.invalidar}
                        </SttButton>
                    }
                    {
                        !contextoFabricalaudo.value &&
                        <SttButton
                            type="button"
                            variant="outlined"
                            color="primary"
                            onClick={voltar}
                        >
                            {strings.voltar}
                        </SttButton>
                    }

                    {
                        contextoFabricalaudo.value &&
                        <SttButton
                            type="button"
                            variant="outlined"
                            color="primary"
                            onClick={ignorarExame}
                        >
                            {strings.proximoExame}
                        </SttButton>
                    }

                </SttGrid>
            </SttGrid>
            <SttGrid container spacing={3}>
                <SttHidden mdUp>
                    <SttGrid item xs={12}>
                        <Imagens exame={exame.exame} />
                    </SttGrid>
                    <SttGrid item xs={12}>
                        <Laudo dados={exame} proximoExame={proximoExame} />
                    </SttGrid>
                </SttHidden>
                <SttHidden only={['xs', 'sm']}>
                    <SttGrid item xs={6}>
                        <StickyBox>
                            <Imagens exame={exame.exame} />
                        </StickyBox>
                    </SttGrid>
                    <SttGrid item xs={6}>
                        <Laudo dados={exame} proximoExame={proximoExame} />
                    </SttGrid>
                </SttHidden>
            </SttGrid>
            {
                invalidar.value &&
                <Invalidar
                    invalidar={invalidar.value}
                    setInvalidar={i => invalidar.value = i}
                    idExame={exame.exame.id_exame}
                    idRede={idRede}
                    callback={proximoExame}
                />
            }
        </>
    );
};

Container.propTypes = {
    exame: PropTypes.object.isRequired,
    idRede: PropTypes.number,
    proximoExame: PropTypes.func,
    loading: PropTypes.func
};

export default Container;