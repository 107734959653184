import { useContext, useEffect } from 'react';
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { useSignal } from '@preact/signals-react';
import {
    SttButton,
    SttContainer,
    SttTranslateHook
} from '@stt-componentes/core';
import DadosExame from './dados-exame';

const Visualizacao = (props) => {

    const { imprimirFn, imprimirProtocoloFn, imprimirTermoFn } = props;
    const location = useLocation();
    const navigate = useNavigate();
    const { id } = useParams();
    const { strings } = useContext(SttTranslateHook.I18nContext);

    const idExame = useSignal(null);
    const viaPaginaInicial = useSignal(false);
    const viaLaudosGraves = useSignal(false);

    useEffect(() => {

        if (id) {
            navigate('/exames', { replace: true });
            navigate('/exames/visualizar', { state: { exame: Buffer.from(id, 'base64').toString('utf-8') } });
        }

        if (location.state?.exame) {
            idExame.value = location.state.exame;
        }

        if (location.state?.viaPaginaInicial) {
            viaPaginaInicial.value = location.state.viaPaginaInicial;
        }

        if (location.state?.viaLaudosGraves) {
            viaLaudosGraves.value = location.state.viaLaudosGraves;
        }
    }, [location.state?.exame]);


    const voltar = () => {
        if (viaPaginaInicial.value) {
            navigate('/', { state: { from: 'visualizar' } });
        }
        else if (viaLaudosGraves.value) {
            navigate('/laudos/graves', { state: { from: 'visualizar' } });
        }
        else { //via pesquisa de Exames
            navigate('/exames', { state: { from: 'visualizar' } });
        }
    }

    return (
        <SttContainer>
            <SttButton
                type="button"
                variant="outlined"
                color="primary"
                onClick={voltar}
                nomarginleft="true"
            >
                {strings.voltar}
            </SttButton>
            {
                idExame.value &&
                <DadosExame 
                    id={idExame} 
                    imprimirFn={imprimirFn}
                    imprimirProtocoloFn={imprimirProtocoloFn}
                    imprimirTermoFn={imprimirTermoFn}
                    voltarFn={voltar}
                />
            }
        </SttContainer>
    )

}

export default Visualizacao;