import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom'
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import axios from 'axios';
import { getHeaders } from '../../request';
import { SttLoading, SttTranslateHook, SttTabs } from '@stt-componentes/core';
import Container from './container';
import HistoricoPaciente from './historicoPaciente';
import { useSignals } from '@preact/signals-react/runtime';
import { useSignal, useSignalEffect } from '@preact/signals-react';
import alerta from '../../signals/alerta';
import { laudar } from '../../signals/laudo';

const useStyles = makeStyles(theme => ({
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
    tabs: {
        padding: 0
    }
}));

const Index = (props) => {
    const { idExame, idRede, proximoExame, loading } = props;

    const { strings } = useContext(SttTranslateHook.I18nContext);
    const location = useLocation();
    const classes = useStyles();
    const ESTOMATO_API_BASE_URL = global.gConfig.url_base_estomato;
    const EXAMES_API_BASE_URL = global.gConfig.url_base_exames;

    const exame = useSignal(null);
    const contextoFabricalaudo = useSignal(location.pathname === '/laudo');
    const abas = useSignal([]);
    const abaAtiva = useSignal('1');

    useSignals();

    const handleCloseAlerta = () => {
        alerta.value = { ...alerta.value, open: false };
        if (contextoFabricalaudo.value) {
            proximoExame();
        } else {
            laudar.value = false;
        }
    }

    useSignalEffect(() => {
        if (idExame) {
            // Inicializa o laudo
            axios
                .get(`${ESTOMATO_API_BASE_URL}/laudo/${idExame}`, { headers: getHeaders() })
                .then((response) => {
                    if (response.data) {
                        let { data } = response.data;
                        exame.value = data;
                        if (contextoFabricalaudo.value) {
                            loading();
                        }
                    }
                })
                .catch(err => {
                    console.log(err);
                    const { response } = err;
                    let msg = strings.mensagemErroGeral;
                    let arrMensagem = [];
                    if (response) {
                        const { data } = response;
                        data.errors.forEach(error => {
                            arrMensagem.push(`- ${error.message}`);
                        });
                        msg = arrMensagem.join('\n');
                    }

                    alerta.value = {
                        title: strings.erro,
                        message: msg,
                        type: 'error',
                        open: true,
                        options: [
                            {
                                title: strings.ok,
                                onClick: handleCloseAlerta

                            }
                        ],
                        onClose: handleCloseAlerta
                    };
                });
        }
    });

    useSignalEffect(() => {
        if (exame.value) {
            axios
                .get(`${EXAMES_API_BASE_URL}/laudo/historico/${idExame}`, { headers: getHeaders() })
                .then((response) => {
                    if (response.data) {
                        if (response.data.data.length > 0) {
                            let dadosAbas = [];

                            // Aba histórico
                            dadosAbas.push({
                                historico: response.data.data,
                                titulo: strings.historico,
                                permanente: true,
                                abas,
                                abaAtiva,
                                conteudo: HistoricoPaciente,
                            });

                            // Aba laudo
                            dadosAbas.push({
                                exame: exame.value,
                                idRede,
                                proximoExame,
                                loading,
                                permanente: true,
                                titulo: strings.laudo,
                                conteudo: Container,
                            });

                            abas.value = dadosAbas;
                        }
                    }
                })
                .catch(err => console.log(err));
        }
    });

    return (
        <>
            <div className={classes.carregando}>
                <SttLoading
                    open={!exame.value && !contextoFabricalaudo.value}
                    text={strings.carregandoExame}
                />
            </div>
            {
                exame.value &&
                (
                    abas.value.length > 0 &&

                    <SttTabs
                        className={classes.tabs}
                        abaAtiva={abaAtiva.value}
                        abas={abas.value}
                        permanente={true}
                        canClose={true}
                        handleCloseTab={(indice) => {
                            abas.value.splice(indice, 1);
                            abaAtiva.value = abas.value.length - 1 + '';
                        }}
                    /> ||

                    <Container exame={exame.value} idRede={idRede} proximoExame={proximoExame} loading={loading} />
                )
            }
        </>
    );
};

Index.propTypes = {
    idExame: PropTypes.number.isRequired,
    proximoExame: PropTypes.func,
    loading: PropTypes.func
};

export default Index;